.main {
	/* color: #555; */
	/* color: #1c887c; */
	color: #666;
	font-weight: bold;
}

.artist {
	color: #777;
}
