.legend {
	margin: 8px 40px 10px;
	font-size: 12px;
	line-height: 1.6em;
	text-align: center;
}

.list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.listItem {
	margin: 0 1.5em .25em 0;
}

.item {
	border-left: 1em solid #333;
	padding-left: 0.5em;
	white-space: nowrap;
	color: #999;
	font-weight: normal;
}

.item, .item:hover {
	text-decoration: none;
}

.item:hover .itemMain {
	text-decoration: underline;
}
